<template> 
<div> 
    <h4>Reports Dashboard</h4> 
    <article> 
        <div class="ml-4"> 
            <li class="lead">What is the Sales Report Performance?</li> 
                <p> (A). It shows a total of the goods or services that a company sells during a specific period.</p>
            
            <li class="lead">What is the OPEX Performance Report Performance?</li> 
                <p> (A). It shows a total indirect costs required to run the day-to-day operations of a business.</p>

            <li class="lead">What is the COGS Performance Report Performance?</li> 
                <p> (A). It shows the total direct costs incurred in producing or purchasing the goods or services that a company sells during a specific period.</p>

            <li class="lead">What Does the Profit and Loss Account Mean?</li>
            <p>
                It is also known as the Income Statement. The P&L account provides an overview of a company's financial performance and profitability. Here's a breakdown:
                                <ul>
                    <li><strong>Turnover:</strong> This refers to the total revenue or sales a business generates over a specific period. It is a measure of business activity and performance.</li>
                    <li><strong>COGS (Cost of Goods Sold):</strong> Represents the direct costs of producing the goods or services sold by the business.</li>
                    <li><strong>OPEX (Operating Expenses):</strong> The indirect costs of running the business, such as administrative and operational expenses.</li>
                    <li><strong>Gross Profit:</strong> The profit a company makes after deducting COGS from its turnover. This measures the efficiency of production or service delivery.</li>
                    <li><strong>Total Expenditure:</strong> The combined total of all expenses, including COGS and OPEX.</li>
                    <li><strong>Net Profit:</strong> The remaining profit after deducting all expenses (COGS and OPEX) from turnover. It indicates overall profitability.</li>
                    <li><strong>Gross Profit Margin:</strong> A percentage metric showing how much of the turnover remains as gross profit. It is calculated as (Gross Profit / Turnover) × 100.</li>
                    <li><strong>Net Profit Margin:</strong> A percentage metric showing how much of the turnover remains as net profit. It is calculated as (Net Profit / Turnover) × 100.</li>
                </ul>
            </p>
        </div>
    </article>
</div>
</template> 
<script> 
export default {
} 
</script>