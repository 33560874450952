<template>
    <div>
        <h4>Billing Management</h4>
        <article>
            <div class="ml-4">
                <li class="lead">How Do I Subscribe?</li>
                <p>(A). The list of packages can be found at the <router-link  to="/business/billing">Subscribe</router-link> Page
                     <br> (B). Click on your desired package and make payment with the available payment methods on the paystack gateway.

                </p>

                <li class="lead">How Do I Activate Subscription?</li>
                <p>(A). Once payment is made, an activation code is sent to your business email, use that activation code to activate your account. <br></p>
            </div>

        </article>
    </div>
</template>
<script>
export default{

}
</script>