<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <!-- <li>
            <a href="http://www.creative-tim.com">
              E.F NETWORK
            </a>
          </li> -->
          <!-- <li>
            <a href="http://blog.creative-tim.com">
              Blog
            </a>
          </li>
          <li>
            <a href="http://www.creative-tim.com/license">
              Licenses
            </a>
          </li> -->
        </ul>
      </nav>
      <div class="copyright pull-right">
        <!-- &copy;  -->
        <!-- <i class="fa fa-heart heart"></i> by
        <a href="https://binarcode.com" target="_blank" rel="noopener">BinarCode</a>.
        <span>Designed by <a href="https://www.creative-tim.com/?ref=pdf-vuejs" rel="noopener" target="_blank">Creative Tim</a>.</span> -->
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
