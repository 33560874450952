<template> 
<div> 
    <h4>Accounting Concepts</h4> 
    <article> 
        <div class="ml-4"> 
            <li class="lead">What is a Ledger?</li> 
            <p> (A). A ledger is the central repository of all financial transactions in a business.
                 <br> (B). It contains categorized accounts, such as assets, liabilities, equity, revenues, and expenses. 
                 <br> (C). Each account in the ledger records the debits and credits associated with transactions, enabling accurate financial tracking and reporting. 
                </p>
                <li class="lead">What are the Types of Expenditures?</li>
            <p>
                (A). Expenditures are the costs incurred by a business to operate. They are divided into two main types:
                <ul>
                    <li><strong>COGS (Cost of Goods Sold):</strong> These are the direct costs associated with producing or purchasing the goods or services a business sells. Examples include raw materials and manufacturing labor.</li>
                    <li><strong>OPEX (Operating Expenses):</strong> These are the indirect costs required to run the business, such as rent, salaries (excluding production staff), marketing, and utilities.</li>
                </ul>
            </p>

            <li class="lead">What is Expenditure Management?</li>
            <p>
                (A). Expenditure management involves planning, monitoring, and controlling a company's spending to maximize efficiency and profitability.
                <br> (B). It ensures that all expenditures align with the company's strategic goals and that resources are allocated wisely.
            </p>

            <li class="lead">What Do Key Financial Metrics Mean?</li>
            <p>
                Below are explanations of common financial terms used in accounting:
                <ul>
                    <li><strong>Turnover:</strong> This refers to the total revenue or sales a business generates over a specific period. It is a measure of business activity and performance.</li>
                    <li><strong>COGS (Cost of Goods Sold):</strong> Represents the direct costs of producing the goods or services sold by the business.</li>
                    <li><strong>OPEX (Operating Expenses):</strong> The indirect costs of running the business, such as administrative and operational expenses.</li>
                    <li><strong>Gross Profit:</strong> The profit a company makes after deducting COGS from its turnover. This measures the efficiency of production or service delivery.</li>
                    <li><strong>Total Expenditure:</strong> The combined total of all expenses, including COGS and OPEX.</li>
                    <li><strong>Net Profit:</strong> The remaining profit after deducting all expenses (COGS and OPEX) from turnover. It indicates overall profitability.</li>
                    <li><strong>Gross Profit Margin:</strong> A percentage metric showing how much of the turnover remains as gross profit. It is calculated as (Gross Profit / Turnover) × 100.</li>
                    <li><strong>Net Profit Margin:</strong> A percentage metric showing how much of the turnover remains as net profit. It is calculated as (Net Profit / Turnover) × 100.</li>
                </ul>
            </p>
        </div>
    </article>
</div>
</template> 
<script> 
export default {
} 
</script>